import React from 'react'
import Layout from '../components-en/layout'
import Head from '../components-en/head'
import { Link, useStaticQuery, graphql } from "gatsby"

import soru from "../images/soru.svg"
import { RiArrowRightSLine } from 'react-icons/ri';
import faq from "../images/faq.svg"


import '../styles/grid.scss'
import '../styles/index.scss'


const HelpPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata{
            email
        }
    }
    }
  `)

  return(
    <Layout>
      <Head title="Help" description="For your questions about Çember, have a look to our list of frequenlty asked questions below." />
      <div className="row yardim">
       <div className="hero-container">
        <div className="container">
          <div className="row">
         
            <div className="col-6">
              <div className="hero-text">
                <h1>How can we help?</h1>
                <p>For your questions about Çember, have a look to our list of frequenlty asked questions below. </p>  
                <p>If you’re having problems about the app, feel free to contact us. We’ll get in touch with you as soon as possible.</p>  
                <p><Link to="contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/>  {data.site.siteMetadata.email} </Link></p>
                <h5>
                   <Link className="cta-button" to="contact">Contact us</Link>
                </h5>        
              </div>
            </div>
            <div className="col-6 center">
              <img src={faq} alt="Frequently asked questions"/> 
            </div>
            
          </div>
        </div>  
      </div>
      </div>
      <div className="row faq">
        <div className="container">
          <div className="row section-title">
            <h1>Frequently Asked Questions <span role="img" aria-label="Emoji"> 🤔 </span> </h1>
          </div>
          <div className="row">
              <ul>
                <li>
                  <span>What is Çember?</span>
                  <p>Çember is an app where users can save money as groups for a common goal. With Çember, users can set a goal, agree on an amount to pay monthly and pay this to one of the users in the group every month. At the end of circle, all users receive the total target amount.</p>
                </li>
                <li>
                  <span>How can I download the app?</span>
                  <p>You can download the app from App Store or Play Store.</p>
                </li>
                <li>
                  <span>How it works?</span>
                  <p>You invite anyone you’d like to save money together to the circle you created. When the target number of people is achieved, circle starts. Payment order is randomized. 
Çember will remind you how much and when to pay. You’ll receive notifications.</p>
                </li>
                <li>
                  <span>How many people can I create a circle with?</span>
                  <p>Minimum 2, maximum 10 people can be in a circle.</p>
                </li>
                <li>
                  <span>Are the payments automated?</span>
                  <p>Not yet. Users send and receive money via their own bank accounts and the one who got payment approves that the payment in the app. We’re working for the bank integration.</p>
                </li>
                <li>
                  <span>How is the order of receiving payment is determined?</span>
                  <p>After you reach the target number of people and the last person joins the circle, the order is determined randomly and the payment dates are listed.  </p>
                </li>
                <li>
                  <span>What happens if someone takes the money and disappears?</span>
                  <p>Çember is a tool to save money with your trusted friends and family members. You can only create circles with people you invite.</p>
                </li>
                <li>
                  <span>Can I join the circles that I see in Discover tab in the app?</span>
                  <p>You can only join the circles you’re invited in this version. You’ll be able to join circles in the future.</p>
                </li>
                <li>
                  <span>Which bank accounts can I use for payments?</span>
                  <p>You can use any bank account you want. Just pick your bank and enter your account information.</p>
                </li>
                <li>
                  <span>How can I do the payments?</span>
                  <p>You send the target amount to the bank accounts of other members of circle. We will remind you when to pay with notifications!</p>
                </li>
                <li>
                  <span>How can I receive the money?</span>
                  <p>When it’s your turn to receive payments, people in your circle will send you the target amount to your defined bank account. Don’t forget to approve the payments you got in Çember.</p>
                </li>
                <li>
                  <span>Can I cancel the circle?</span>
                  <p>For the moment, it is not possible to cancel the circles. We’re working on this feature.</p>
                </li>
                <li>
                  <span>Can I change the number of people in a circle?</span>
                  <p>You can’t make changes in the circle you created now. We’re working on this feature.</p>
                </li>
                <li>
                  <span>Why should I save money with Çember?</span>
                  <p>With Çember, you can save money with people you trust for a common goal and have the motivation when you’re alone. You can buy your dream items without debts.</p>
                </li>
                <li>
                  <span>Can I save in euros or dollars?</span>
                  <p>You can use your Turkish Lira, Euro or Dollar accounts and save in these currencies.</p>
                </li>
                <li>
                  <span>How much money can I save with Çember?</span>
                  <p>You can save maximum 500 ₺, 500 € or 500 $ in a month. You can save with 10 people for 10 months. So, do the math. <span role="img" aria-label="angel">😇</span></p>
                </li>
                <li>
                  <span>Will everyone see my personal information when I sign up?</span>
                  <p>When you sign up, people who are not your circle can only see your name. You name, surname and bank account information will be visible to users in your circle.</p>
                </li>
              </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="col-12">
            <div className="sorukutu">
              <h1>If you have questions, get in touch!</h1>
              <Link to="contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Contact us </span> </Link>
              <img src={soru} alt="Ask a question"/>
            </div>
          </div>
        </div>
    </div>

    </Layout>
  )
}

export default HelpPage